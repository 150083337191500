.itemTypes .itemType {
  width: 50%;
  padding: 5px;
  vertical-align: top;
}

.itemTypes .itemType :global(table) {
  width: auto;
}

.itemTypes .itemType :global(table .itemTypeName) {
  vertical-align: middle;
  font-size: large;
}

.itemTypes .itemType > div {
  padding: 10px;
  background-color: white;
  color: black;
}
