*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root,
#page {
  height: 100%;
  width: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  color: #888888;
  background-color: #fffeee;
  margin: 0;
  font-size: 90%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 1.802em;
  border-left: solid 15px #f44903;
  /* background: url(images/indent.png) no-repeat left center; */
  text-indent: 10px;
}

h2 {
  font-size: 1.602em;
  border-left: solid 10px #f44903;
  text-indent: 10px;
}

h3 {
  font-size: 1.424em;
  border-left: solid 5px #f44903;
  text-indent: 10px;
}

h4 {
  font-size: 1.266em;
}

h5 {
  font-size: 1.125em;
}

h6 {
  font-size: 1em;
}

a {
  color: #f44903;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

table {
  width: 100%;
}

td,
th {
  padding: 10px;
  text-align: left;
  color: #000000;
}
th,
td.head {
  vertical-align: top;
}
td {
  vertical-align: middle;
}

th {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  background-color: #e0e0e0;
}

tr.even {
  background-color: #f9f9f9;
}

tr.odd {
  background-color: #fdfdfd;
}

.head,
.foot {
  font-weight: bold;
  color: #000000;
  background-color: #e0e0e0;
}

.even {
  background-color: #f9f9f9;
}

.odd {
  background-color: #fdfdfd;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.hidden {
  display: none;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.block .blockTitle {
  height: 21px;
  margin-bottom: 0.4rem;
  padding: 0 10px 0 32px;
  color: #369;
  line-height: 21px;
  font-weight: bold;
}

.block .blockContent {
  padding: 0 10px;
  font-size: 13px;
}

header {
  min-width: 960px;
  padding-top: 26px;
}

header .logo {
  display: block;
  float: left;
  width: 260px;
  height: 100px;
  background-image: url(./images/logo.png);
}

header .utility {
  float: right;
  text-align: right;
}

header .utility .incf-japan-node {
  margin: 10px 10px 10px 0;
  display: block;
  float: left;
  width: 160px;
  height: 32px;
  background-image: url(./images/incf-japan-node.png);
}

header .utility .search-box {
  margin: 10px 0 10px 10px;
  float: right;
}

header .utility .search-box input {
  width: 195px;
  padding: 9px 10px;
  border: none;
  background: none;
  font: normal 11px Arial, Helvetica, sans-serif;
  color: #808080;
  border: solid 3px #e99e0f;
  border-radius: 10px;
}

header ul.mainmenu {
  margin: 0 40px;
  padding: 0;
  text-align: right;
  list-style-type: none;
}

header ul.mainmenu li {
  margin: 0 2px;
  width: 120px;
  height: 35px;
  line-height: 35px;
  font-size: 0.8em;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: #888888;
  background-color: #ffffff;
  border: 1px #cccccc solid;
  border-width: 1px 1px 0 1px;
}

header ul.mainmenu li a {
  color: #888888;
}

header ul.mainmenu li .submenu {
  width: 120px;
  position: relative;
  float: left;
  top: -1px;
  background-color: #e0e0e0;
  z-index: 10;
  animation: fadeIn 1s ease 0s 1 normal;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

header ul.mainmenu li .submenu .item {
  display: block;
  margin: 1px;
  background-color: #ffffff;
}

footer {
  min-width: 960px;
  margin: 20px auto;
  text-align: center;
}

#page {
  width: 960px;
  margin: 0 auto;
  line-height: 1.5;
}

#main {
  padding: 20px 20px;
  color: #ffffff;
  background-color: #000000;
  width: 960px;
}

.mainContent {
  margin: 0 20px;
}

.welcome,
.overview {
  text-align: justify;
}

.welcome ul {
  padding-left: 20px;
  margin: 10px 0;
}

.welcome ul li {
  list-style-type: square;
}

.overview .overview1,
.overview .overview2 {
  text-align: center;
}

.news .itemHead {
  padding: 10px;
  border-bottom: 1px #f0f0f0 solid;
}
.news .itemTitle {
  font-weight: bold;
  font-size: 1.266em;
}
.news .itemInfo {
  text-align: right;
  padding: 3px;
  font-size: 85%;
}
.news .itemInfo .username {
  color: #f44903;
}
.news .itemText ul {
  padding-left: 20px;
}
.news .itemText .notice {
  font-weight: bold;
  margin-left: 20px;
}
.news .itemText .notice ul {
  padding-left: 0;
}
.news .itemText .notice ul li {
  list-style-type: none;
}
.categories .container {
  float: left;
  width: 270px;
  margin: 8px;
  background-color: #e8e8e8;
  border-radius: 5px;
}
.categories .container.reseach_areas {
  width: 556px;
}
.categories .name {
  padding: 10px 0 10px 14px;
  font-size: 13px;
  font-weight: bold;
  color: #000000;
}
.categories .content {
  padding: 10px;
  height: 389px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  border-radius: 5px;
  line-height: 1.7;
}
.categories .content ul {
  margin: 10px 0;
  padding-left: 20px;
}

#main .bottom_of_content_body,
#main .jumpToTop {
  text-align: right;
}

#main .pico_body {
  margin: 0;
  padding: 0;
}

#main .openneuroFreeKeyword > div:not(:last-child),
#main .openneuroAuthor > div:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid #333333;
}
#main .openneuroFreeKeyword > div:not(:first-child),
#main .openneuroAuthor > div:not(:first-child) {
  padding-top: 10px;
}
#main .openneuroRelatedTo > tbody > tr > td {
  padding: 10px 0;
}
#main .openneuroRelatedTo > tbody > tr:first-child > td {
  padding-top: 0;
}
#main .openneuroRelatedTo > tbody > tr:last-child > td {
  padding-bottom: 0;
}
.listIcon {
  width: 80px;
  text-align: center;
}
.listIcon img {
  vertical-align: middle;
}
.listBody {
  vertical-align: top;
}
