.downloadButton {
    display: inline-block;
    padding: 7px 20px;
    text-decoration: none !important;
    font-weight: normal !important;
    background: chocolate;
    color: #ffffff !important;
    border: solid 1px #cccccc;
    box-shadow: 2px 2px #bbbbbb;
    border-radius: 5px;
    white-space: nowrap;
}

.downloadButton:hover {
    background: orangered !important;
    border: solid 1px #888888;
}

.downloadButton:active {
    transform: translate(2px, 2px);
    box-shadow: none;
}